<template>
    <div class="sideblock-select-lens-thickness">
        <div class="sideblock-select-lens-thickness__options">
            <div class="sideblock-select-lens-thickness__options-item"
                v-for="(item, i) in items"
                v-bind:key="i"
                v-on:click="optionClickHandler(item)"
                >
                <div class="sideblock-select-lens-thickness__options-item-illustration"
                    v-bind:style="{ backgroundImage: `url(${item.image})` }"
                />
                <div class="sideblock-select-lens-thickness__options-item-info">
                    <div class="sideblock-select-lens-thickness__options-item-info-title"
                        v-text="item.title"
                    />
                    <div class="sideblock-select-lens-thickness__options-item-info-description"
                        v-text="item.description"
                    />
                    <div class="sideblock-select-lens-thickness__options-item-info-botline">
                        <div class="sideblock-select-lens-thickness__options-item-info-botline-price"
                            v-if="item.minPriceFormatted"
                            v-text="item.minPriceFormatted"
                        />
                        <div class="sideblock-select-lens-thickness__options-item-info-botline-trigger">
                            Выбрать
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'sideblock-select-lens-thickness',
    props: {
        items: {
            type: Array,
        },
    },
    methods: {
        optionClickHandler(node) {
            this.$emit('set-node', node);
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.sideblock-select-lens-thickness {
    &__options {
        &-item {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            border-bottom: 1px solid @color-gray-darkest;
            width: 100%;

            cursor: pointer;

            transition: color @duration-fast @easing-default, background-color @duration-fast @easing-default;
            &:hover {
                color: @color-gray-lightest;

                background-color: @color-accent-cold;
            }
            &-illustration {
                flex: 0 0 auto;
                width: 168px;
                height: 168px;

                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;
            }
            &-info {
                flex: 1 1 auto;
                padding: 24px 20px;
                &-title {
                    margin-bottom: 8px;

                    text-transform: uppercase;
                }
                &-description {
                    margin-bottom: 24px;
                }
                &-botline {
                    display: flex;
                    align-items: baseline;
                    justify-content: space-between;
                    width: 100%;
                    &-price {
                        flex: 0 0 auto;
                        padding-right: 16px;
                    }
                    &-trigger {
                        flex: 0 0 auto;

                        .mixin-link-decoration();
                    }
                }
            }
        }
    }
    @media @media-sm-down {
        &__options {
            &-item {
                display: block;
                &:hover {
                    color: inherit;

                    background-color: inherit;
                }
                &-illustration {
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }
}
</style>
