<template>
    <section class="page-order">
        <template v-if="order">
            <div class="page-order__loader"
                v-if="!getOrdersItemResult"
                >
                <div class="page-order__loader-container">
                    <ui-loader />
                </div>
            </div>
            <template v-else>
                <div class="page-order__info">
                    <template v-if="order.isPaid">
                        <div class="page-order__info-title">
                            Спасибо за заказ
                        </div>
                        <div class="page-order__info-text">
                            Номер вашего заказа: {{ order.id }}
                            <br/>
                            Мы свяжемся с вами, если возникнут вопросы.
                        </div>
                    </template>
                    <template v-else>
                        <div class="page-order__info-title">
                            ЗАКАЗ #{{ order.id }} НЕ ОПЛАЧЕН
                        </div>
                        <div class="page-order__info-text">
                            Ваш заказ оформлен, но не оплачен.
                            <br />
                            Мы свяжемся с вами в ближайшее время. Вы можете самостоятельно оплатить заказ или написать нам в чат, если у вас есть вопросы. Все заказы хранятся в вашем <a v-bind:href="dashboardLink">Личном кабинете</a>.
                        </div>
                        <div class="page-order__info-buttons">
                            <div class="page-order__info-buttons-item"
                                v-if="getOrdersItemResult.payment_url"
                                >
                                <transition name="fade">
                                    <div class="page-order__info-buttons-item-loader"
                                        v-if="isPaymentUrlRequestLoading"
                                        >
                                        <div class="page-order__info-buttons-item-loader-container">
                                            <ui-loader />
                                        </div>
                                    </div>
                                </transition>
                                <ui-button
                                    v-on:click="paymentHandler"
                                    is-uppercase
                                    >
                                    Оплатить
                                </ui-button>
                            </div>
                            <div class="page-order__info-buttons-item">
                                <ui-button
                                    v-on:click="chatHandler"
                                    is-bordered
                                    is-uppercase
                                    >
                                    Написать в чат
                                </ui-button>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="page-order__gallery"
                    v-if="products"
                    >
                    <div class="page-order__gallery-item"
                        v-for="(product, index) in products"
                        v-bind:key="index"
                        v-bind:style="{
                            backgroundImage: `url(${product.image ? product.image : lensImage })`,
                            backgroundColor: product.image ? null : '#fff',
                            backgroundSize: product.image ? null : 'contain',
                        }"
                        >
                        <div class="page-order__gallery-item-quantity"
                            v-if="product.quantity > 1 && !product.isCertificate"
                            v-text="product.quantity"
                        />
                    </div>
                </div>
                <div class="page-order__giftcard"
                    v-if="order.isPaid && getOrdersItemResult.giftcard"
                    >
                    Вы всегда можете найти копию сертификата в <a v-bind:href="dashboardLink" target="_blank">Личном кабинете</a> и скачать <span class="page-order__giftcard-trigger" v-on:click="download('pdf')">файлом в PDF</span> или <span class="page-order__giftcard-trigger" v-on:click="download('png')">картинкой в PNG</span>.
                </div>
                <div class="page-order__store"
                    v-if="order.store"
                    >
                    <div class="page-order__store-info">
                        <div class="page-order__store-info-title">
                            {{ order.store.name }}
                        </div>
                        <div class="page-order__store-info-text">
                            <span class="page-order__store-info-text-accent">
                                {{ order.store.area.name }}
                            </span>
                            <br/>
                            <br/>
                            {{ order.store.address }}
                            <br/>
                            {{ order.store.work_time }}
                            <br/>
                            {{ order.store.contacts }}
                            <br/>
                            <br/>
                            <div class="page-order__store-info-text-links">
                                <a v-bind:href="`/shop/stores/${order.store.id}/#plugin-store-info-${order.store.id}`">На карте</a>
                                <br/>
                                <a v-bind:href="`/shop/stores/${order.store.id}/`">Узнать о магазине больше</a> →
                            </div>
                        </div>
                    </div>
                    <div class="page-order__store-image"
                        v-bind:style="{ backgroundImage: order.store.image ? `url(${order.store.image})` : 'none' }"
                        >
                    </div>
                </div>
                <div class="page-order__address"
                    v-else-if="getOrdersItemResult.shipping_code === 'no-shipping'"
                    >
                    <template v-if="order.isPaid">
                        <div class="page-order__address-title">
                            Доставка на email
                        </div>
                        <div class="page-order__address-text"
                            v-if="getOrdersItemResult.order_information && getOrdersItemResult.order_information.gift_email_from"
                            >
                            {{ getOrdersItemResult.order_information.gift_email_from }}
                        </div>
                        <div class="page-order__address-text"
                            v-if="getOrdersItemResult.order_information && getOrdersItemResult.order_information.gift_email_to"
                            >
                            {{ getOrdersItemResult.order_information.gift_email_to }}
                        </div>
                    </template>
                    <template v-if="order.isPaid">
                        <br />
                        <br />
                        <div class="page-order__address-text">
                            Успешность доставки на Email не всегда зависит от нас.
                            <br />
                            Поэтому у вас всегда есть возможность скачать копию сертификата.
                        </div>
                    </template>
                </div>
                <div class="page-order__address"
                    v-else
                    >
                    <div class="page-order__address-title">
                        Адрес доставки
                    </div>
                    <div class="page-order__address-text"
                        v-if="getOrdersItemResult.shipping_address.first_name || getOrdersItemResult.shipping_address.last_name"
                        >
                        {{ getOrdersItemResult.shipping_address.first_name }} {{ getOrdersItemResult.shipping_address.last_name }}
                    </div>
                    <div class="page-order__address-text"
                        v-if="getOrdersItemResult.shipping_address"
                        >
                        {{ getOrdersItemResult.shipping_address.line3 }}, {{ getOrdersItemResult.shipping_address.line4 }}, {{ getOrdersItemResult.shipping_address.line1 }}, {{ getOrdersItemResult.shipping_address.postcode }}
                        <br />
                        {{ getOrdersItemResult.shipping_address.phone_number }}
                    </div>
                </div>
            </template>
        </template>
        <template v-else>
            <div class="page-order__info">
                <div class="page-order__info-title">
                    Нет информации о заказе
                </div>
            </div>
        </template>
    </section>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';
import { mainClient } from '~/network';
import utils from '~/utils';

export default {
    name: 'page-order',
    inject: [ 'staticPathRoot' ],
    data: () => ({
        order: null,
        isPaymentUrlRequestLoading: false,
    }),
    computed: {
        ...mapState('orders', [
            'getOrdersItemResult',
            'getOrdersItemIsLoading',
            'getOrdersItemError',
        ]),
        lensImage() {
            return this.staticPathRoot + '/lenses/thickness/thin.png';
        },
        products() {
            if (!this.getOrdersItemResult) {
                return null;
            }
            return this.getOrdersItemResult.order_lines.map(line => ({
                image: line.product.images?.length > 0 ? line.product.images[0].small : null,
                quantity: line.quantity,
                isCertificate: line.product.isCertificate,
            }));
        },
        isFitting() {
            return this.getOrdersItemResult?.isFitting || !!utils.common.parseUrl({ onlyWithValues: false }).find(x => x.title === 'fitting');
        },
        dashboardLink() {
            return config.urls.cabinet + '/#history-' + this.order.id;
        },
        quantity() {
            if (this.item.quantity <= 1) {
                return '';
            }
            if (this.item.product.isAccessory) {
                return ` ${this.item.quantity} шт.`;
            }
            return '';
        },
    },
    methods: {
        async paymentHandler() {
            this.isPaymentUrlRequestLoading = true;
            const paymentRequest = await mainClient.$client.request({
                url: this.getOrdersItemResult.payment_url,
                baseURL: '/',
                method: 'GET',
            });
            if (paymentRequest.status === 200) {
                const productsEcommerceData = this.getOrdersItemResult.order_lines
                    .filter(line => line.product.title !== 'Доставка')
                    .map(line => ({
                        ...line.product.ecommerce,
                        quantity: line.quantity,
                    }));
                this.$store.dispatch('checkout/showPaymentWidget', {
                    ...paymentRequest.data,
                    email: this.getOrdersItemResult.guest_email,
                    onSuccess: () => {
                        this.$store.commit('checkout/saveOrderToLocalstorage', { ...this.order, isPaid: true });
                        this.$store.commit('checkout/purchaseAnalytics', {
                            transaction: this.getOrdersItemResult.number,
                            total: this.getOrdersItemResult.total_incl_tax,
                            tax: this.getOrdersItemResult.total_incl_tax - this.getOrdersItemResult.total_excl_tax,
                            shipping: this.getOrdersItemResult.shipping_incl_tax,
                            products: productsEcommerceData,
                            type: this.isFitting ? 'fitting' : 'checkout',
                        });
                    },
                    onFail: () => { window.location.reload(); },
                });
            } else {
                this.$store.commit('modals/push', {
                    name: 'common',
                    props: {
                        title: 'Произошла ошибка',
                        text: 'При запросе оплаты произошла ошибка',
                    },
                });
            }
            this.isPaymentUrlRequestLoading = false;
        },
        chatHandler() {
            utils.common.chatraOpen({
                callback: () => {
                    utils.common.chatraSendAutoMessage('Здравствуйте! Готовы помочь по заказу ' + this.order.id);
                },
            });
        },
        download(mode) {
            this.$store.commit('modals/push', {
                name: 'render-giftcard',
                props: {
                    mode,
                    url: this.getOrdersItemResult.giftcard,
                },
            });
        },
    },
    beforeMount() {
        this.order = JSON.parse(
            window.localStorage.getItem(
                config.localStorage.prefix + config.localStorage.checkoutSuccessAffix
            ) ||
            'null'
        );
    },
    mounted() {
        if (this.order?.id) {
            this.$store.dispatch('orders/getOrdersItem', { id: this.order.id });
        }
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-order {
    .typography-body-md();
    .mixin-full-height();
    .container();

    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    max-width: 780px;
    padding: 60px 20px 120px;
    margin: auto;
    &__loader {
        display: flex;
        align-items: center;
        justify-content: center;
        &-container {
            width: 100px;
        }
    }
    &__info {
        margin-bottom: 16px;
        &-title {
            margin-bottom: 10px;

            text-transform: uppercase;
            text-align: center;
        }
        &-text {
            text-align: center;

            max-width: 516px;
            margin: 0 auto;
        }
        &-buttons {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            padding: 24px 0 48px;
            margin: -4px;
            &-item {
                position: relative;

                flex: 0 0 auto;
                margin: 4px;
                width: 220px;
                &-loader {
                    .transition-fade();

                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    z-index: 1;

                    display: flex;
                    align-items: center;
                    justify-content: center;

                    background: @color-gray-main;
                    &-container {
                        width: 32px;
                    }
                }
            }
        }
    }
    &__gallery {
        &-item {
            position: relative;

            width: 180px;
            height: 100px;
            margin: 0 auto 10px;

            background-color: @color-gray-lighter;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            &-quantity {
                .typography-body-md();

                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;

                display: flex;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                margin: 8px;
                padding: 0;
                border-radius: 50%;

                color: @color-gray-lightest;
                text-align: center;

                background-color: @color-gray-darkest;
            }
        }
    }
    &__giftcard {
        .typography-body-md();

        width: 100%;
        max-width: 516px;
        margin: 70px auto 0;

        text-align: center;
        &-trigger {
            .mixin-link-decoration();

            cursor: pointer;
        }
    }
    &__store {
        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;
        margin-top: 100px;
        &-info {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            &-title {
                font-size: 2.8rem;
                line-height: 4rem;
                text-transform: uppercase;
            }
            &-text {
                margin-top: auto;
                margin-bottom: 0;
                &-accent {
                    text-transform: uppercase;
                }
                &-links {
                    line-height: 2.6rem;
                }
            }
        }
        &-image {
            flex: 0 0 auto;
            width: 50%;
            padding-top: 34.6%;
            margin-left: 20px;

            background-color: @color-gray-lighter;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
    }
    &__address {
        margin-top: 36px;

        text-align: center;
        &-title {
            margin-bottom: 14px;

            text-transform: uppercase;
        }
    }
    @media @media-md-down {
        &__instagram {
            &-item {
                width: ~'calc(50% - 40px)';
            }
        }
    }
    @media @media-sm-down {
        &__store {
            flex-direction: column-reverse;
            &-info {
                width: 100%;
                &-title {
                    display: none;
                }
            }
            &-image {
                width: 100%;
                padding-top: 69.2%;
                margin-left: 0;
                margin-bottom: 16px;
            }
        }
    }
}
</style>
