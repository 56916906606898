<template>
    <div class="plugin-common-catalog">
        <div class="plugin-common-catalog__search"
            v-if="searchQuery !== null"
            >
            <div class="plugin-common-catalog__search-wrapper">
                <div class="plugin-common-catalog__search-query">
                    Результаты для «<span v-text="searchQuery" />»
                </div>
                <div class="plugin-common-catalog__search-remove"
                    v-on:click="removeSearch"
                    >
                    <icon name="close" />
                </div>
            </div>
        </div>
        <div class="plugin-common-catalog__list">
            <plugin-common-catalog-list
                v-if="products !== null && products.length > 0"
                v-bind:is-search="searchQuery !== null"
                v-bind:page="page"
                v-bind:page-size="pageSize"
                v-bind:last-viewed-item="lastViewedItem"
                v-on:last-viewed-item-clear="clearLastViewedItem"
                v-on:last-viewed-item-update="updateLastViewedItem"
            />
        </div>
        <div class="plugin-common-catalog__empty"
            v-if="!isLoading && products !== null && products.length === 0"
            >
            Ничего не найдено. Попробуй другие комбинации в <span class="plugin-common-catalog__empty-trigger" v-on:click="isAnyFilterActive = true">фильтре</span>
        </div>
        <div class="plugin-common-catalog__loader"
            v-if="isLoading || products === null"
            >
            <div class="plugin-common-catalog__loader-container">
                <ui-loader />
            </div>
        </div>
        <div class="plugin-common-catalog__filters"
            v-if="!hideFilters"
            v-bind:class="{ _active: isAnyFilterActive }"
            >
            <plugin-common-catalog-filters
                v-bind:is-active="isAnyFilterActive"
                v-on:toggle="filtersToggleHandler"
            />
        </div>
        <common-intersection-observer
            v-if="!isLoading"
            v-on:intersect="scrollLoadingHandler"
        />
    </div>
</template>

<script>
import config from '~/config';
import utils from '../../../../utils';

// localStorage key to store clicked item data
const localStorageLastViewKey = config.localStorage.prefix + config.localStorage.affixes.lastViewedItem;

export default {
    name: 'plugin-common-catalog',
    props: {
        hideFilters: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        isAnyFilterActive: false,
        page: 1,
        pageSize: 24,
        queriesToIgnore: [ 'roistat_visit' ],
        listIntersectionObserver: null,
        // lastViewedItem is a last item user clicked data (id and page number)
        // we store it to be able to scroll to corresponding item
        // when user goes back to catalog page after visiting item page
        lastViewedItem: JSON.parse(window.localStorage.getItem(localStorageLastViewKey)),
        isLastViewedItemRequested: false,
        isWaitingToScroll: false,
    }),
    computed: {
        searchQuery() {
            return this.$store.getters['filters/searchQuery'];
        },
        activeFilters() {
            return this.$store.getters['filters/activeFilters'];
        },
        filterItems() {
            return this.$store.getters['filters/filterItems'];
        },
        products() {
            return this.$store.getters['catalog/state'].products;
        },
        isLoading() {
            return this.$store.getters['catalog/state'].getProductsIsLoading ||
                this.$store.getters['catalog/state'].getProductsDebouncedIsLoading ||
                this.isWaitingToScroll;
        },
        pagesCount() {
            return Math.max(Math.ceil(this.$store.getters['catalog/state'].productsCount / this.pageSize), 1);
        },
    },
    methods: {
        removeSearch() {
            this.$store.commit('filters/setSearchQuery', null);
        },
        filtersToggleHandler() {
            this.isAnyFilterActive = !this.isAnyFilterActive;
        },
        getData(add = false) {
            if (add) {
                this.page += 1;
            } else {
                this.page = 1;
            }
            const params = {
                add,
                page: this.page,
            };
            if (this.lastViewedItem && !this.isLastViewedItemRequested) {
                this.isLastViewedItemRequested = true;
                params.page = 1;
                params.pageSize = this.pageSize * this.lastViewedItem.page;
                this.$store.dispatch(add ? 'catalog/getProducts' : 'catalog/getProductsDebounced', params);
                this.page = this.lastViewedItem.page;
            } else {
                this.$store.dispatch(add ? 'catalog/getProducts' : 'catalog/getProductsDebounced', params);
            }
        },
        parseUrl() {
            const queries = utils.common.parseUrl();
            const result = {};
            queries.forEach(query => {
                if (query.title === 'q') {
                    this.$store.commit('filters/setSearchQuery', query.value);
                } else if (this.queriesToIgnore.indexOf(query.title) < 0) {
                    result[query.title] = query.value.split(',').map(x => {
                        const parsed = parseInt(x, 10);
                        if (isNaN(parsed)) {
                            return x;
                        }
                        return parsed;
                    });
                }
            });
            if (Object.keys(result).length > 0) {
                this.$store.commit('filters/setActiveFilters', { ...this.activeFilters, ...result });
            }
        },
        rebuildUrl() {
            let queries = [];
            Object.keys(this.activeFilters).forEach(key => {
                if (this.activeFilters[key].length > 0 && this.queriesToIgnore.indexOf(key) < 0) {
                    queries.push(`${key}=${this.activeFilters[key].join(',')}`);
                }
            });
            if (this.searchQuery !== null) {
                queries.push('q=' + this.searchQuery);
            }
            let result = '?' + queries.join('&');
            window.history.replaceState('', '', window.location.pathname + result);
        },
        resizeFixingHandler() {
            if (window.innerWidth < 768) {
                this.$store.commit('common/increaseBodyFixedCounter');
                window.removeEventListener('resize', this.resizeFixingHandler);
                window.addEventListener('resize', this.resizeUnfixingHandler);
            }
        },
        resizeUnfixingHandler() {
            if (window.innerWidth >= 768) {
                this.$store.commit('common/decreaseBodyFixedCounter');
                window.removeEventListener('resize', this.resizeUnfixingHandler);
                window.addEventListener('resize', this.resizeFixingHandler);
            }
        },
        scrollLoadingHandler() {
            if (this.page < this.pagesCount) {
                this.getData(true);
            }
        },
        updateLastViewedItem(newLastViewedItem) {
            this.lastViewedItem = newLastViewedItem;
            window.localStorage.setItem(
                localStorageLastViewKey,
                JSON.stringify(newLastViewedItem),
            );
        },
        clearLastViewedItem() {
            window.localStorage.setItem(
                localStorageLastViewKey,
                JSON.stringify(null),
            );
            this.lastViewedItem = null;
            this.isWaitingToScroll = false;
        },
    },
    beforeMount() {
        if (this.lastViewedItem) {
            this.isWaitingToScroll = true;
        }
    },
    mounted() {
        this.parseUrl();
        this.$store.dispatch('filters/getFilterItems');
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeFixingHandler);
        window.removeEventListener('resize', this.resizeUnfixingHandler);
    },
    watch: {
        filterItems(newVal) {
            if (newVal !== null) {
                this.getData();
            }
        },
        activeFilters(newVal) {
            if (newVal !== null) {
                this.getData();
                this.rebuildUrl();
            }
        },
        searchQuery() {
            this.rebuildUrl();
            this.getData();
        },
        isAnyFilterActive(newVal) {
            if (newVal) {
                if (window.innerWidth < 768) {
                    this.$store.commit('common/increaseBodyFixedCounter');
                    window.addEventListener('resize', this.resizeUnfixingHandler);
                    window.removeEventListener('resize', this.resizeFixingHandler);
                } else {
                    window.addEventListener('resize', this.resizeFixingHandler);
                    window.removeEventListener('resize', this.resizeUnfixingHandler);
                }
            } else {
                if (window.innerWidth < 768) {
                    this.$store.commit('common/decreaseBodyFixedCounter');
                }
                window.removeEventListener('resize', this.resizeUnfixingHandler);
                window.removeEventListener('resize', this.resizeFixingHandler);
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-common-catalog {
    .typography-body-md();
    .container();

    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    width: 100%;
    &__search {
        order: -2;
        min-height: 40px;

        background-color: @color-accent-cold;
        &-wrapper {
            .container();
            .container-paddings();

            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding-right: 10px;
        }
        &-query {
            padding: 13px 20px 13px 0;
        }
        &-remove {
            padding: 10px;

            cursor: pointer;
        }
    }
    &__filters {
        order: -1;
        width: 100%;
    }
    &__empty {
        padding: 22px 20px;
        &-trigger {
            .mixin-link-decoration();

            cursor: pointer;
        }
    }
    &__loader {
        width: 300px;
        padding: 100px;
        margin: auto;
    }
    &__trigger {
        height: 0;

        opacity: 0;
        pointer-events: none;
    }
    @media @media-sm-down {
        &__filters {
            position: sticky;
            bottom: 0;
            z-index: 2;

            order: 10;
            overflow: auto;
            &._active {
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 10001;
            }
        }
    }
}
</style>
