<template>
    <div class="sideblock-select-lens-cover">
        <div class="sideblock-select-lens-cover__options">
            <div class="sideblock-select-lens-cover__options-item"
                v-for="(item, i) in items"
                v-bind:key="i"
                v-on:click="optionClickHandler(item, i)"
                >
                <div class="sideblock-select-lens-cover__options-item-illustration">
                    <div class="sideblock-select-lens-cover__options-item-illustration-video"
                        v-if="item.video"
                        >
                        <sideblock-select-lens-cover-video
                            v-bind:src="item.video"
                            v-bind:poster="item.image"
                        />
                    </div>
                    <div class="sideblock-select-lens-cover__options-item-illustration-image"
                        v-else
                        v-bind:style="{ backgroundImage: `url(${item.image})` }"
                    />
                </div>
                <div class="sideblock-select-lens-cover__options-item-info">
                    <div class="sideblock-select-lens-cover__options-item-info-title"
                        v-text="item.title"
                    />
                    <div class="sideblock-select-lens-cover__options-item-info-lens"
                        v-text="`${item.product.title} (${item.product.attributes.find(x => x.code === 'Покрытие').value})`"
                    />
                    <div class="sideblock-select-lens-cover__options-item-info-description"
                        v-text="item.description"
                    />
                    <div class="sideblock-select-lens-cover__options-item-info-botline">
                        <div class="sideblock-select-lens-cover__options-item-info-botline-price"
                            v-text="item.product.priceDoubledFormatted"
                        />
                        <div class="sideblock-select-lens-cover__options-item-info-botline-trigger">
                            Добавить в корзину
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { productDetailsView, productImpression, productClick } from '~/utils/ecommerce';
export default {
    name: 'sideblock-select-lens-cover',
    props: {
        value: {
            type: String,
        },
        items: {
            type: Array,
        },
    },
    methods: {
        optionClickHandler(node, i) {
            productClick({
                index: i + 1,
                product: {
                    ...node.product.ecommerce,
                    listId: 'sidebar_lenses',
                    listTitle: 'Линзы',
                    quantity: 2,
                },
            });
            this.$emit('set-node', node);
        },
    },
    mounted() {
        this.items.forEach((x, i) => {
            productDetailsView({
                ...x.product.ecommerce,
                quantity: 2,
                listId: 'sidebar_lenses',
                listTitle: 'Линзы',
            });
            productImpression({
                product: {
                    ...x.product.ecommerce,
                    quantity: 2,
                },
                listId: 'sidebar_lenses',
                listTitle: 'Линзы',
                index: i,
            });
        });
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.sideblock-select-lens-cover {
    &__options {
        &-item {
            display: flex;
            align-items: stretch;
            justify-content: flex-start;
            border-bottom: 1px solid @color-gray-darkest;
            width: 100%;

            cursor: pointer;

            transition: color @duration-fast @easing-default, background-color @duration-fast @easing-default;
            &:hover {
                color: @color-gray-lightest;

                background-color: @color-accent-cold;
            }
            &._basic &-illustration-video {
                margin-left: 2px;
            }
            &._enhanced &-illustration {
                &-video {
                    margin-top: -1px;
                    margin-left: -1px;
                }
            }
            &._pc &-illustration {
                &-video {
                    margin-top: 2px;
                    margin-left: 2px;
                }
            }
            &-illustration {
                flex: 0 0 auto;
                width: 168px;
                height: 168px;
                border-radius: 50%;
                margin: 24px 8px 24px 24px;
                overflow: hidden;

                background-color: @color-gray-lightest;

                box-shadow: 0 0 8px 8px @color-gray-lightest;

                transform: translateZ(0);
                &-video {
                    display: block;
                    height: 100%;
                    width: 100%;
                }
                &-image {
                    width: 100%;
                    height: 100%;

                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                }
            }
            &-info {
                flex: 1 1 auto;
                padding: 24px 20px;
                &-title {
                    margin-bottom: 8px;

                    text-transform: uppercase;
                }
                &-lens {
                    margin-bottom: 8px;

                    opacity: 0.5;
                }
                &-description {
                    margin-bottom: 24px;
                }
                &-botline {
                    display: flex;
                    align-items: baseline;
                    justify-content: space-between;
                    width: 100%;
                    &-price {
                        flex: 0 0 auto;
                        padding-right: 16px;
                    }
                    &-trigger {
                        flex: 0 0 auto;

                        .mixin-link-decoration();
                    }
                }
            }
        }
    }
    @media @media-sm-down {
        &__options {
            &-item {
                display: block;
                &:hover {
                    color: inherit;

                    background-color: inherit;
                }
                &-illustration {
                    margin-left: auto;
                    margin-right: auto;
                    border-radius: 0;
                    overflow: visible;
                    &-video {
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}
</style>
