<template>
    <div class="page-checkout-step-payment">
        <template v-if="isReady">
            <div class="page-checkout-step-payment__content"
                v-if="isActive"
                >
                <div class="page-checkout-step-payment__content-block">
                    <page-checkout-step-payment-method />
                </div>
                <div class="page-checkout-step-payment__content-block"
                     v-if="mode ==='fitting'"
                    >
                    Принимаем оплату с любых российских карт и международных карт МИР.
                </div>
                <div class="page-checkout-step-payment__content-block">
                    ( ・_・)ノ Не забудьте выключить VPN перед переходом к оплате
                </div>
                <div class="page-checkout-step-payment__content-button">
                    <ui-button
                        v-on:click="submitHandler"
                        v-bind:is-disabled="$v.$dirty && $v.$invalid"
                        is-uppercase
                        v-text="buttonText"
                    />
                </div>
                <div class="page-checkout-step-payment__content-agreement">
                    Нажимая кнопку «{{ buttonText }}», я принимаю условия <a class="page-checkout-step-payment__content-agreement-trigger" v-bind:href="urls.oferta" target="_blank">договора оферты</a> и <a class="page-checkout-step-payment__content-agreement-trigger" v-bind:href="urls.policy" target="_blank">политики конфеденциальности</a>
                </div>
            </div>
        </template>
        <span v-else-if="isActive">Загрузка...</span>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';

export default {
    name: 'page-checkout-step-payment',
    inject: [ 'pageCheckoutEventBus', 'cartModuleName', 'mode' ],
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        step: {
            type: Object,
        },
    },
    data: () => ({
        urls: {
            oferta: config.urls.oferta,
            policy: config.urls.policy,
        },
        innerValidations: {
            method: true,
        },
    }),
    validations() {
        const result = {
            innerValidations: {
                isValid: value => Object.values(value).every(x => !!x),
            },
        };
        return result;
    },
    computed: {
        ...mapState('paymentMethods', [ 'getPaymentMethodsResult' ]),
        cart() {
            return this.$store.state[this.cartModuleName].getCartResult;
        },
        checkoutData() {
            return this.$store.state.checkout.data;
        },
        isReady() {
            return this.getPaymentMethodsResult;
        },
        isCustomCountry() {
            return this.$store.getters['checkout/isCustomCountry'];
        },
        isCustomCity() {
            return this.$store.getters['checkout/isCustomCity'];
        },
        isSelfPickup() {
            return this.$store.getters['checkout/isSelfPickup'];
        },
        isShipping() {
            return this.$store.getters['checkout/isShipping'];
        },
        isShippingNeeded() {
            return !this.cart || this.cart.isShippingNeeded;
        },
        isShippingFilled() {
            return !this.isShippingNeeded ||
                (this.isSelfPickup && this.checkoutData.store) ||
                (this.isShipping && this.checkoutData.shippingMethod);
        },
        buttonText() {
            if (!this.checkoutData.paymentMethod || this.mode === 'fitting') {
                return 'Продолжить';
            } else if (this.checkoutData.paymentMethod.code === 'cash' || this.checkoutData.paymentMethod.code === 'gift-card') {
                return 'Оформить заказ';
            } else {
                return 'Перейти к оплате';
            }
        },
    },
    methods: {
        validationHandler(newVal) {
            this.innerValidations = { ...this.innerValidations, ...newVal };
        },
        submitHandler() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.pageCheckoutEventBus.$emit('step-completed', this.step);
        },
    },
    mounted() {
        this.pageCheckoutEventBus.$on('payment-submit', this.submitHandler);
        this.pageCheckoutEventBus.$on('payment-validation', this.validationHandler);
        if (!this.getPaymentMethodsResult) {
            this.$store.dispatch('paymentMethods/getPaymentMethods', {
                basket_key: this.cartModuleName,
            });
        }
    },
    beforeDestroy() {
        this.pageCheckoutEventBus.$off('payment-submit');
        this.pageCheckoutEventBus.$off('payment-validation');
    },
    watch: {
        '$v.$dirty'(newVal) {
            this.$store.commit('checkout/setEntity', { entity: 'isPaymentDirty', value: newVal });
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-checkout-step-payment {
    &__content {
        &-block {
            margin-bottom: 20px;
            &._warning {
                color: @color-accent-warm;
            }
        }
        &-button {
            height: 48px;
            margin-top: 24px;
        }
        &-agreement {
            margin-top: 16px;
            &-trigger {
                .mixin-link-decoration();

                cursor: pointer;
            }
        }
    }
    &__result {
        padding-left: 16px;
        padding-bottom: 16px;

        color: @color-gray-dark;
    }
}
</style>
