<template>
    <div class="page-checkout-cart-promo">
        <div class="page-checkout-cart-promo__trigger"
            v-bind:class="{ _active: isActive }"
            v-on:click="toggle"
            >
            У вас есть сертификат или промокод?
        </div>
        <template v-if="isActive">
            <form class="page-checkout-cart-promo__form"
                v-on:submit.prevent="submitHandler"
                >
                <div class="page-checkout-cart-promo__form-container">
                    <div class="page-checkout-cart-promo__form-input">
                        <ui-input-text
                            v-bind:model="fields.vouchercode"
                            v-bind:is-invalid="$v.$dirty && $v.$invalid"
                            v-model="fields.vouchercode.value"
                        />
                    </div>
                    <div class="page-checkout-cart-promo__form-button">
                        <ui-button type="submit">
                            Готово!
                        </ui-button>
                    </div>
                </div>
                <div class="page-checkout-cart-promo__form-error"
                    v-if="$v.$invalid && $v.$dirty && error !== null"
                    v-text="error"
                    >
                </div>
                <div class="page-checkout-cart-promo__form-error"
                    v-else-if="backendError !== null && !ignoreBackendError"
                    >
                    <span v-if="backendError === 'Можно использовать только один сертификат'">
                        Чтобы использовать второй сертификат <a href="#chatra_trigger">напишите в чат</a>.
                    </span>
                    <span v-else-if="backendError === 'Voucher code unknown'">
                        Проверьте правильность кода или <a href="#chatra_trigger">напишите нам в чат</a>.
                    </span>
                    <span v-else-if="backendError.indexOf('Этот купон уже был использован') || backendError.indexOf(' просрочен')">
                        Не можем применить этот код (скидка не распространяется на товары в корзине или код уже недействителен). <a href="#chatra_trigger">Напишите в чат</a>, если у вас есть вопросы.
                    </span>
                    <span v-else>
                        Что-то пошло не так. Пожалуйста, <a href="#chatra_trigger">напишите нам в чат</a>.
                    </span>
                </div>
            </form>
            <div class="page-checkout-cart-promo__result"
                v-if="lastVoucher !== null"
                >
                {{ lastVoucher.is_gift ? 'Сертификат' : 'Промокод' }} <span v-text="lastVoucher.code" /> успешно применен
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import forms from '~/forms';
import utils from '~/utils';

export default {
    name: 'page-checkout-cart-promo',
    data: () => ({
        isActive: false,
        lastVoucher: null,
        ignoreBackendError: false,
        fields: {
            vouchercode: { ...forms.fields.vouchercode, isAutofocus: true },
        },
    }),
    validations: {
        fields: {
            vouchercode: { ...forms.validations.vouchercode },
        },
    },
    computed: {
        ...mapState('cart', [
            'applyVoucherError',
            'applyVoucherResult',

            'removeVoucherResult',
        ]),
        certificates() {
            return this.$store.getters['cart/certificates'];
        },
        error() {
            if (!this.fields.vouchercode.errors || !this.$v) {
                return null;
            }
            const errorKeys = Object.keys(this.$v.fields.vouchercode.value).filter(x => x.substring(0, 1) !== '$').filter(x => !this.$v.fields.vouchercode.value[x]);
            const validErrorKey = errorKeys.find(x => this.fields.vouchercode.errors[x] !== undefined);
            if (validErrorKey !== undefined) {
                return this.fields.vouchercode.errors[validErrorKey];
            }
            return null;
        },
        backendError() {
            const error = this.$store.state.cart.applyVoucherError;
            if (!error) {
                return null;
            }
            if (error.non_field_errors || error.reason) {
                return utils.network.parseError(error, true);
            }
            return this.$store.state.cart.applyVoucherError;
        },
    },
    methods: {
        toggle() {
            this.isActive = !this.isActive;
        },
        submitHandler() {
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            this.ignoreBackendError = false;
            this.$store.dispatch('cart/applyVoucher', { vouchercode: this.fields.vouchercode.value });
        },
    },
    watch: {
        'fields.vouchercode.value'() {
            this.ignoreBackendError = true;
        },
        removeVoucherResult(newVal) {
            if (newVal !== null) {
                this.lastVoucher = null;
            }
        },
        applyVoucherError(newVal) {
            if (newVal !== null) {
                this.lastVoucher = null;
            }
        },
        applyVoucherResult(newVal) {
            if (newVal !== null) {
                this.fields.vouchercode.value = '';
                this.$v.$reset();
                this.lastVoucher = newVal;
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-checkout-cart-promo {
    .typography-body-md();

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    &__trigger {
        margin-bottom: 13px;

        .mixin-link-decoration();

        cursor: pointer;
        &._active {
            text-decoration: none;
        }
    }
    &__result {
        color: @color-accent-cold;
    }
    &__form {
        &-container {
            display: flex;
            height: 40px;
            width: 100%;
            max-width: 320px;
            margin-bottom: 5px;
        }
        &-input {
            flex: 1 1 auto;
            height: 100%;
        }
        &-button {
            flex: 1 1 auto;
            min-width: 100px;
            max-width: 140px;
            height: 100%;
            margin-left: -2px;

            background: @color-accent-cold;
        }
        &-error {
            color: @color-accent-warm;
        }
    }
    @media @media-sm-down {
        &__trigger {
            margin-bottom: 0;
        }
        &__form {
            margin-top: 13px;
        }
    }
}
</style>
