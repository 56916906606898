<template>
    <div class="sideblock-availability">
        <template v-if="status === 'loading'">
            <div class="sideblock-availability__loader">
                <div class="sideblock-availability__loader-container">
                    <ui-loader />
                </div>
            </div>
        </template>
        <template v-if="step === 'store'">
            <template v-if="stores.length > 0">
                <div class="sideblock-availability__text">
                    Сейчас в наличии в магазинах:
                </div>
                <div class="sideblock-availability__stores">
                    <div class="sideblock-availability__stores-item"
                        v-for="(item, index) in stores"
                        v-bind:key="index"
                        v-on:click="selectStore(index)"
                        >
                        <div class="sideblock-availability__stores-item-area"
                            v-text="item.area.name"
                            >
                        </div>
                        <div class="sideblock-availability__stores-item-info">
                            <span class="sideblock-availability__stores-item-info-title"
                                v-text="item.name"
                            />
                            <br/>
                            <span v-text="item.address" />
                            <br/>
                            <span v-text="item.work_time" />
                        </div>
                        <div class="sideblock-availability__stores-item-availability">
                            <span v-if="item.availableStock >= 4"
                                v-text="'В наличии'"
                            />
                            <span v-else>
                                <span class="sideblock-availability__stores-item-availability-flag">⚑</span> Осталось мало!
                            </span>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else-if="status !== 'loading'">
                <div class="sideblock-availability__text">
                    Скорее всего этого товара сейчас нет в наличии в наших оффлайн магазинах.
                    <br />
                    Обратите внимание, что сайт не всегда знает о наличии в оффлайн магазинах товаров из раздела "Аксессуары", но вы всегда можете уточнить информацию у нас <a href="#chatra_trigger">в чате</a>.
                </div>
            </template>
        </template>
        <template v-if="step === 'confirm'">
            <div class="sideblock-availability__confirm">
                <sideblock-availability-confirm
                    v-bind:store="currentStore"
                    v-bind:item="currentChild"
                />
            </div>
        </template>
        <template v-if="step === 'success'">
            <div class="sideblock-availability__success">
                <div class="sideblock-availability__success-product">
                    <div class="sideblock-availability__success-product-image"
                        v-bind:style="{ backgroundImage: `url(${currentChild.images[0].small})`}"
                        >
                    </div>
                    <div class="sideblock-availability__success-product-info">
                        <span class="sideblock-availability__success-product-info-title" v-text="currentChild.title"></span> <span v-text="color"></span>
                        <br/>
                        уже ждут вас в нашем магазине
                        <br/>
                        (сегодня и до <span v-text="`${new Date().getDate() + 3}.${new Date().getMonth() + 1}`"></span>)
                    </div>
                </div>
                <div class="sideblock-availability__success-shop">
                    <div class="sideblock-availability__success-shop-image"
                        v-if="currentStore.image"
                        v-bind:style="{ backgroundImage: `url(${currentStore.image})`}"
                        >
                    </div>
                    <div class="sideblock-availability__title">
                        <span v-text="currentStore.area.name"></span>
                    </div>
                    <div class="sideblock-availability__success-shop-info">
                        <div class="sideblock-availability__success-shop-info-text">
                            <span v-text="currentStore.name"></span>
                            <br/>
                            <span v-text="currentStore.address"></span>
                            <br/>
                            <span v-text="currentStore.work_time"></span>
                            <br/>
                            <a v-bind:href="`tel:${currentStore.contacts.replace(/[() -]/g,'')}`" v-text="currentStore.contacts"></a>
                        </div>
                        <div class="sideblock-availability__success-shop-info-links">
                            <div class="sideblock-availability__success-shop-info-links-item">
                                <a v-bind:href="`/shop/stores/${currentStore.id}/#plugin-store-info-${currentStore.id}`">На карте</a>
                            </div>
                            <div class="sideblock-availability__success-shop-info-links-item">
                                <a v-bind:href="`/shop/stores/${currentStore.id}/`">Узнать о магазине больше</a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="sideblock-availability__success-instagram">
                    <common-instagram />
                </div> -->
            </div>
        </template>
    </div>
</template>

<script>
import forms from '~/forms';
import { mainClient } from '~/network';

export default {
    name: 'sideblock-availability',
    props: {
        item: {
            type: Object,
        },
        currentChild: {
            type: Object,
        },
    },
    data: () => ({
        status: 'loading',
        step: 'store',
        stores: [],
        selectedStore: null,
        form: {
            valid: true,
            commonErrors: [],
            fields: {
                name: { ...forms.fields.name },
                phone: { ...forms.fields.phone, strictPhoneCheck: true },
                email: { ...forms.fields.email },
            },
        },
    }),
    validations: {
        form: {
            fields: {
                name: { ...forms.validations.name },
                phone: { ...forms.validations.phone },
                email: { ...forms.validations.email },
            },
        },
    },
    computed: {
        currentStore() {
            if (this.selectedStore === null) {
                return;
            }
            return this.stores[this.selectedStore];
        },
        color() {
            const color = this.currentChild.attributes.find(x => x.code === 'Цвет оправы');
            return color !== undefined ? color.value : '';
        },
    },
    methods: {
        async showFitting() {
            this.status = 'loading';
            this.step = 'store';

            const stockrecords = await mainClient.shop.getStockrecords({ url: this.currentChild.stockrecords });
            const stores = await mainClient.shop.getStores();

            if (stockrecords.error !== null || stores.error !== null) {
                let error, status;
                if (stockrecords.error !== null) {
                    error = stockrecords.error;
                    status = stockrecords.status;
                } else {
                    error = stores.error;
                    status = stores.status;
                }
                console.error(status, error);
            } else {
                const filteredStores = [];
                const filtredStockrecords = stockrecords.data.results.filter(record => record.num_in_stock - record.num_allocated > 0);

                filtredStockrecords.forEach(record => {
                    const validStoreForRecord = stores.data.results.find(store => store.partner.id === record.partner);
                    if (validStoreForRecord !== undefined) {
                        validStoreForRecord.availableStock = record.num_in_stock - record.num_allocated;
                        filteredStores.push(validStoreForRecord);
                    };
                });
                this.stores = filteredStores
                    .filter(store => store.availableStock > 1)
                    .sort((a, b) => a.area.name > b.area.name ? 1 : -1);
                if (this.stores.length === 1) {
                    this.selectStore(0);
                }
            }
            this.status = 'loaded';
        },
        selectStore(index) {
            this.selectedStore = index;
            this.step = 'confirm';
        },
        back() {
            this.selectedStore = null;
            this.step = 'store';
        },
        updateFormData(newData) {
            this.form.fields = {
                ...this.form.fields,
                ...newData,
            };
        },
        async addToFitting() {
            const params = {
                url: this.currentChild.api_url,
                quantity: 1,
            };
            const { data, error, status } = await mainClient.shop.addToFitting(params);
            if (error !== null) {
                console.error(status, error);
            } else {
                this.checkoutFitting(data.url);
            }
        },
        async checkoutFitting(basketUrl) {
            const name = this.form.fields.name.value.split(' ');
            const address = {
                first_name: name[0] ? name[0] : '',
                last_name: name[1] ? name[1] : '',
                line1: 'Default Address',
                line4: 'Default City',
                postcode: '10001',
                state: '',
                country: '/shop/countries/RU/',
                phone_number: this.form.fields.phone.value,
            };
            const params = {
                guest_email: this.form.fields.email.value,
                basket: basketUrl,
                shipping_address: address,
                billing_address: address,
                payment: {
                    cash: {
                        enabled: true,
                        amount: 0,
                    },
                },
                shipping_method_code: 'no-shipping-required',
                comment: 'Тип заказа: Примерка',
                stock_id: this.currentStore.id,
                phone_number: this.form.fields.phone.value,
            };
            const { error, status } = await mainClient.shop.checkout(params);
            if (error !== null) {
                console.error(status, error);
                this.form.commonErrors = [error];
            } else {
                this.step = 'success';
            }
            this.status = 'loaded';
        },
    },
    mounted() {
        this.showFitting();
    },
    beforeDestroy() {
        this.$store.state.sideblocks.overriders.pop.availability = undefined;
    },
    watch: {
        step(newVal) {
            this.$emit('scroll-top');
            if (newVal === 'confirm') {
                this.$store.state.sideblocks.overriders.pop.availability = this.back;
            } else {
                this.$store.state.sideblocks.overriders.pop.availability = undefined;
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.sideblock-availability {
    .mixin-sideblock();

    position: relative;

    min-height: 100%;
    padding: 0;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: fade(@color-gray-lightest, 50%);
        &-container {
            width: 100px;
        }
    }
    &__text {
        padding: 20px;
        &-trigger {
            .mixin-link-decoration();

            cursor: pointer;
        }
    }
    &__stores {
        position: relative;
        z-index: 1;

        flex: 0 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start;
        width: 100%;
        border-top: 1px solid @color-gray-darkest;
        &-item {
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 50%;
            min-height: 200px;
            padding: 16px 29px 16px 20px;
            border-bottom: 1px solid @color-gray-darkest;

            cursor: pointer;

            transition: background-color @duration-fast @easing-default;
            &:hover {
                background-color: @color-accent-bronze;
            }
            &:nth-child(odd) {
                border-right: 1px solid @color-gray-darkest;
            }
            &-area {
                margin-bottom: 16px;

                text-transform: uppercase;
            }
            &-info {
                margin-bottom: 24px;
                &-title {
                    text-decoration: underline;
                }
            }
            &-availability {
                margin-top: auto;
                margin-bottom: 0;
                &-flag {
                    font-size: 1.25em;
                }
            }
        }
    }
}
</style>
