<template>
    <div class="page-dashboard-history-order">
        <transition name="fade">
            <div class="page-dashboard-history-order__loader"
                v-if="isPaymentUrlRequestLoading"
                >
                <div class="page-dashboard-history-order__loader-container"
                    v-bind:class="{ _expanded: expanded }"
                    >
                    <ui-loader />
                </div>
            </div>
        </transition>
        <div class="page-dashboard-history-order__header">
            <div class="page-dashboard-history-order__header-item" v-text="`#${order.number}`" />
            <div class="page-dashboard-history-order__header-item" v-text="totalFormatted" />
            <div class="page-dashboard-history-order__header-item" v-text="date" />
            <div class="page-dashboard-history-order__header-item">
                <span v-text="status" />
                <span class="page-dashboard-history-order__header-item-hint"
                    v-if="statusHint !== null"
                    >
                    <span v-text="'?'" />
                    <span class="page-dashboard-history-order__header-item-hint-content" v-html="statusHint" />
                </span>
            </div>
            <div class="page-dashboard-history-order__header-item">
                <div class="page-dashboard-history-order__header-item-action"
                    v-if="isPaymentAvailable"
                    >
                    <span
                        v-on:click="payHandler"
                        v-text="'Оплатить заказ'"
                        target="_blank"
                    />
                </div>
                <div class="page-dashboard-history-order__header-item-action"
                    v-if="order.trackingUrl"
                    >
                    <a v-bind:href="order.trackingUrl"
                        target="_blank"
                        v-text="'Отследить заказ'"
                    />
                </div>
                <div class="page-dashboard-history-order__header-item-action"
                    v-if="!expanded"
                    v-on:click="expand"
                    v-text="'Показать детали'"
                />
                <div class="page-dashboard-history-order__header-item-action"
                    v-else
                    v-on:click="collapse"
                    v-text="'Скрыть детали'"
                />
            </div>
        </div>
        <div class="page-dashboard-history-order__details"
            v-bind:class="{ '_expanded': expanded }"
            >
            <div class="page-dashboard-history-order__details-header">
                <div class="page-dashboard-history-order__details-header-item"
                    v-text="'Состав заказа:'"
                />
                <div class="page-dashboard-history-order__details-header-item"
                    v-text="'Адрес доставки'"
                />
            </div>
            <div class="page-dashboard-history-order__details-content">
                <div class="page-dashboard-history-order__details-content-item">
                    <page-dashboard-history-order-item
                        v-for="(item, index) in items"
                        v-bind:key="index"
                        v-bind:item="item"
                        v-bind:order="order"
                    />
                </div>
                <div class="page-dashboard-history-order__details-content-item">
                    <template v-if="order.order_information.gift_email_from">
                        Отправка на email:
                        <br />
                        {{ order.order_information.gift_email_from }}
                        <br />
                        <template v-if="order.order_information.gift_email_to">
                            С копией на:
                            <br />
                            {{ order.order_information.gift_email_to }}
                            <br />
                        </template>
                        <br />
                    </template>
                    <template v-if="order.shipping_method">
                        <br />
                        {{ order.shipping_method }}
                        <br />
                    </template>
                    <template v-if="order.shipping_address">
                        <template v-if="typeof order.shipping_address === 'string'">
                            {{ order.shipping_address }}
                            <br />
                        </template>
                        <template v-else>
                            <template v-if="order.shipping_address.first_name.length > 0 || order.shipping_address.last_name.length > 0">
                                {{ order.shipping_address.first_name }} {{ order.shipping_address.last_name }}
                                <br />
                            </template>
                            <template v-if="order.shipping_address.phone_number && order.shipping_address.phone_number.length > 0">
                                {{ order.shipping_address.phone_number }}
                                <br />
                            </template>
                            <template v-if="order.shipping_address.line4.length > 0 && order.shipping_address.line4 !== 'Default City'">
                                {{ order.shipping_address.line4 }}
                                <br />
                            </template>
                            <template v-if="order.shipping_address.line1.length > 0 && order.shipping_address.line1 !== 'Default Address'">
                                {{ order.shipping_address.line1 }}
                                <br />
                            </template>
                        </template>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import { mainClient } from '~/network';
import config from '~/config';
import utils from '~/utils';

export default {
    name: 'page-dashboard-history-order',
    props: [ 'order', 'preExpanded' ],
    data: () => ({
        expanded: false,
        isPaymentUrlRequestLoading: false,
    }),
    computed: {
        date() {
            return moment(this.order.date_placed).format('DD.MM.YYYY');
        },
        status() {
            return this.order.order_information?.status?.name || 'Обрабатывается';
        },
        statusHint() {
            return this.order.order_information?.status?.description || null;
        },
        isPaymentAvailable() {
            return this.order.payment_url && this.order.status !== 'Canceled';
        },
        totalFormatted() {
            return utils.formats.formatNumerWithSpaces(parseFloat(this.order.total_incl_tax)) + ' ' + config.currencies.rub.symbol;
        },
        items() {
            const result = this.order.order_lines;
            if (this.order.applied_gift_voucher.code) {
                result.push({
                    price_currency: this.order.currency,
                    price_incl_tax: -1 * this.order.applied_gift_voucher.discount,
                    product: {
                        title: `Скидка по коду ${this.order.applied_gift_voucher.code}`,
                        images: [],
                        attributes: [],
                    },
                });
            }
            if (this.order.manual_order_lines) {
                this.order.manual_order_lines.forEach(line => {
                    result.push({
                        price_currency: this.order.currency,
                        price_incl_tax: line.line_price,
                        product: {
                            title: line.product_name,
                            images: [],
                            attributes: [],
                        },
                    });
                });
            }
            if (this.order.voucher_discounts?.length > 0) {
                this.order.voucher_discounts.forEach(voucher => {
                    if (voucher.voucher.code.toLowerCase() !== config.fitting.voucher.toLowerCase()) {
                        result.push({
                            price_currency: this.order.currency,
                            price_incl_tax: -1 * voucher.amount,
                            product: {
                                title: `Скидка по коду ${voucher.voucher.code}`,
                                images: [],
                                attributes: [],
                            },
                        });
                    }
                });
            }
            if (this.order.shipping_code !== 'self-pickup') {
                result.push({
                    price_currency: this.order.currency,
                    price_incl_tax: this.order.shipping_incl_tax,
                    product: {
                        title: 'Доставка',
                        images: [],
                        attributes: [],
                    },
                });
            }
            return result;
        },
    },
    methods: {
        expand() {
            this.expanded = true;
        },
        collapse() {
            this.expanded = false;
        },
        async payHandler() {
            this.isPaymentUrlRequestLoading = true;
            const paymentRequest = await mainClient.$client.request({
                url: this.order.payment_url,
                baseURL: '/',
                method: 'GET',
            });
            if (paymentRequest.status === 200) {
                const productsEcommerceData = this.order.order_lines
                    .filter(line => line.product && line.product.title !== 'Доставка')
                    .map(line => ({
                        ...line.product.ecommerce,
                        quantity: line.quantity,
                    }));
                this.$store.dispatch('checkout/showPaymentWidget', {
                    ...paymentRequest.data,
                    email: this.order.guest_email,
                    onSuccess: () => {
                        this.$store.commit('checkout/saveOrderToLocalstorage', {
                            id: this.order.number,
                            checkoutTimestamp: Date.now(),
                            isPaid: true,
                        });
                        this.$store.commit('checkout/purchaseAnalytics', {
                            transaction: this.order.number,
                            total: this.order.total_incl_tax,
                            tax: this.order.total_incl_tax - this.order.total_excl_tax,
                            shipping: this.order.shipping_incl_tax,
                            products: productsEcommerceData,
                            type: this.order.isFitting ? 'fitting' : 'checkout',
                        });
                    },
                    onFail: () => { window.location.reload(); },
                });
            } else {
                this.$store.commit('modals/push', {
                    name: 'common',
                    props: {
                        title: 'Произошла ошибка',
                        text: 'При запросе оплаты произошла ошибка',
                    },
                });
            }
            this.isPaymentUrlRequestLoading = false;
        },
    },
    beforeMount() {
        if (this.preExpanded) {
            this.expanded = true;
        }
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-dashboard-history-order {
    position: relative;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 1px;
        z-index: 10;

        display: flex;
        align-items: center;
        justify-content: center;

        background-color: fade(@color-gray-lightest, 50%);
        &-container {
            width: 50px;
            &._expanded {
                width: 100px;
            }
        }
    }
    &__header {
        display: flex;
        width: 100%;
        &-item {
            flex: 0 0 16.66%;
            padding: 20px;
            border-bottom: 1px solid @color-gray-darkest;
            overflow: visible;
            &:first-child {
                flex: 0 0 33.33%;
            }
            &:last-child {
                border-bottom: none;
            }
            &-action {
                .mixin-link-decoration();

                cursor: pointer;
                & ~ & {
                    margin-top: 18px;
                }
            }
            &-hint {
                position: relative;

                display: inline-block;
                width: 19px;
                height: 19px;
                padding: 1px 0 0 1px;
                border: 1px solid @color-gray-darkest;
                margin-left: 7px;

                text-align: center;

                border-radius: 50%;
                cursor: default;
                &-content {
                    position: absolute;
                    left: 50%;
                    top: ~'calc(100% + 20px)';
                    z-index: 1;

                    display: none;
                    width: 240px;
                    padding: 20px;
                    margin-left: -120px;

                    color: @color-gray-darkest;

                    background: @color-gray-lightest;

                    box-shadow: 0 0 30px 0 @color-accent-cold;

                    cursor: auto;
                }
                &:hover & {
                    &-content {
                        display: block;
                    }
                }
            }
        }
    }
    &__details {
        display: none;
        padding-right: 16.66%;
        &._expanded {
            display: block;
        }
        &-header {
            display: flex;
            width: 100%;
            border-bottom: 1px solid @color-gray-darkest;
            &-item {
                flex: 0 0 40%;
                padding: 20px;
                border-right: 1px solid @color-gray-darkest;
                overflow: hidden;
                &:first-child {
                    flex: 0 0 60%;
                }
            }
        }
        &-content {
            display: flex;
            width: 100%;
            border-bottom: 1px solid @color-gray-darkest;
            &-item {
                flex: 0 0 40%;
                padding: 20px;
                border-right: 1px solid @color-gray-darkest;
                overflow: hidden;
                &:first-child {
                    flex: 0 0 60%;
                    display: flex;
                    flex-direction: column;
                    padding: 0;
                }
            }
        }
    }
    @media @media-sm-down {
        &__header {
            &-item {
                &:last-child {
                    border-bottom: 1px solid @color-gray-darkest;
                }
            }
        }
        &__details {
            padding-right: 0;
            margin-bottom: 60px;
            &-header {
                display: none;
            }
            &-content {
                flex-direction: column;
                &-item {
                    border-right: none;
                    & ~ & {
                        border-top: 1px solid @color-gray-darkest;
                    }
                }
            }
        }
    }
    @media @media-xs-down {
        &__header {
            flex-direction: column;
            &-item {
                padding: 10px 20px;
                border-bottom: none;
                &:first-child {
                    padding-top: 20px;
                }
                &:last-child {
                    padding-bottom: 20px;
                    border-bottom: 1px solid @color-gray-darkest;
                }
            }
        }
    }
}
</style>
