<template>
    <div class="modal-auth">
        <transition name="fade">
            <div class="modal-auth__loader"
                v-if="isLoading"
                >
                <div class="modal-auth__loader-container">
                    <ui-loader />
                </div>
            </div>
        </transition>
        <div class="modal-auth__title">
            Войти или создать аккаунт
        </div>
        <div class="modal-auth__form">
            <ui-form
                v-bind:model="fields"
                v-bind:validation="$v"
                v-bind:submit-handler="submitHandler"
                v-on:update="updateFormData"
            />
            <div class="modal-auth__form-error"
                v-if="error"
                v-text="error"
            />
            <div class="modal-auth__form-button">
                <ui-button
                    v-on:click="submitHandler"
                    v-text="'Получить код'"
                    is-uppercase
                />
            </div>
        </div>
        <div class="modal-auth__disclaimer">
            Создавая аккаунт, я принимаю условия <a class="modal-auth__disclaimer-trigger" v-bind:href="urls.oferta" target="_blank">договора оферты</a> и <a class="modal-auth__disclaimer-trigger" v-bind:href="urls.policy" target="_blank">политики конфеденциальности</a>.
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';
import forms from '~/forms';
import utils from '~/utils';

export default {
    name: 'modal-auth',
    props: {
        phone: {
            type: String,
            default: null,
        },
        onSuccess: {
            type: Function,
            default: null,
        },
    },
    data: () => ({
        fields: {
            phone: { ...forms.fields.phone, value: '+7', isAutofocus: true },
        },
        urls: {
            oferta: config.urls.oferta,
            policy: config.urls.policy,
        },
        code: null,
    }),
    validations: {
        fields: {
            phone: { ...forms.validations.phone },
        },
    },
    computed: {
        ...mapState('user', [
            'authResult',
            'authIsLoading',
            'authError',
        ]),
        isLoading() {
            return this.authIsLoading;
        },
        error() {
            return utils.network.parseError(this.authError, true);
        },
    },
    methods: {
        updateFormData(newData) {
            this.fields = newData;
        },
        submitHandler() {
            if (this.$v.$invalid) {
                this.$v.$touch();
                return;
            }
            if (this.code) {
                this.auth();
            } else {
                this.showSmsModal();
            }
        },
        auth() {
            this.$store.dispatch('user/auth', {
                phone: this.fields.phone.value,
                code: this.code,
            });
        },
        showSmsModal() {
            this.$store.commit('modals/push', {
                name: 'sms-verification',
                props: {
                    phone: this.fields.phone.value,
                    callback: this.setCode,
                },
            });
        },
        setCode(code) {
            this.code = code;
            this.submitHandler();
        },
    },
    beforeMount() {
        if (this.phone) {
            this.fields.phone.value = this.phone;
        }
    },
    watch: {
        authIsLoading(newVal) {
            if (!newVal && this.authResult) {
                if (this.onSuccess) {
                    this.onSuccess();
                }
                this.$store.commit('modals/pop');
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.modal-auth {
    .mixin-modal();

    position: relative;

    max-width: 320px;
    padding: 40px;

    box-shadow: 0 0 60px 0 @color-accent-cold;
    &__loader {
        .transition-fade();

        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        background: @color-gray-lightest;
        &-container {
            width: 50px;
        }
    }
    &__title {
        margin-bottom: 36px;

        text-transform: uppercase;
    }
    &__form {
        width: 100%;
        max-width: 240px;
        &-checkbox {
            margin-top: 20px;
        }
        &-error {
            margin-bottom: 36px;

            color: @color-accent-warm;
        }
        &-button {
            height: 48px;
            margin-top: 20px;
        }
    }
    &__disclaimer {
        margin-top: 16px;
        &-trigger {
            .mixin-link-decoration();

            cursor: pointer;
        }
    }
    @media @media-md-down {
        &__title {
            margin-bottom: 9px;
        }
    }
}
</style>
