<template>
    <span class="common-card-cart-prescription">
        <input class="common-card-cart-prescription__input"
            v-on:change="updatePrescriptionFile"
            ref="fileInput"
            type="file"
            accept="image/*"
        />
        <template v-if="!getCartResult.prescription">
            <span class="common-card-cart-prescription__trigger"
                v-on:click="triggetFileInput"
                v-text="'Прикрепить рецепт'"
            /> <span class="common-card-cart-prescription__hint">
                <ui-hint position="bottom">
                    Вы можете прикрепить фотографию рецепта сейчас (JPG, PNG, PDF, HEIC(iPhone)) или указать в комментарии к заказу, что вам нужна консультация нашего специалиста.
                </ui-hint>
            </span>
        </template>
        <template v-else>
            <span class="common-card-cart-prescription__text"
                v-text="prescriptionFileTitle"
            />
            <br />
            <span class="common-card-cart-prescription__trigger"
                v-on:click.stop="removePrescription"
                v-text="'Удалить'"
            />
        </template>
        <template v-error>
            <br />
            <span class="common-card-cart-prescription__error"
                v-text="error"
            />
        </template>
    </span>
</template>

<script>
import { mapState } from 'vuex';
import utils from '~/utils';

export default {
    name: 'common-card-cart-prescription',
    props: {
        basketId: {
            type: [ Number, String ],
        },
    },
    data: () => ({
        prescriptionFile: null,
        fileNameError: null,
    }),
    computed: {
        ...mapState('cart', [
            'getCartResult',
            'getCartIsLoading',
            'getCartError',
        ]),
        ...mapState('lenses', [
            'uploadPrescriptionResult',
            'uploadPrescriptionIsLoading',
            'uploadPrescriptionError',
        ]),
        cartPrescription() {
            return this.getCartResult?.prescription;
        },
        error() {
            if (this.fileNameError) {
                return this.fileNameError;
            }
            if (!this.uploadPrescriptionError) {
                return null;
            }
            return utils.network.parseError(this.uploadPrescriptionError, true);
        },
        prescriptionFileTitle() {
            const name = this.cartPrescription || this.prescriptionFile?.name;
            if (name) {
                return `Рецепт: ${name}`;
            }
            return 'Рецепт загружен';
        },
    },
    methods: {
        removePrescriptionFile() {
            this.prescriptionFile = null;
            const fileInput = this.$refs.fileInput;
            if (!fileInput) {
                return;
            }
            fileInput.value = '';
        },
        removePrescription() {
            this.removePrescriptionFile();
            this.setPrescription();
        },
        updatePrescriptionFile(e) {
            this.fileNameError = null;
            const file = e.target.files[0];
            if (!file) {
                this.prescriptionFile = null;
            } else {
                if (file.name.length > 100) {
                    this.fileNameError = 'Имя файла длиннее 100 символов';
                    this.prescriptionFile = null;
                    return;
                }
                this.prescriptionFile = file;
            }
            this.setPrescription();
        },
        triggetFileInput() {
            const fileInput = this.$refs.fileInput;
            if (!fileInput) {
                return;
            }
            fileInput.click();
        },
        setPrescription() {
            const formData = new FormData();
            if (this.prescriptionFile) {
                formData.append('image', this.prescriptionFile);
            } else {
                this.removePrescriptionFile();
            }
            formData.append('basket_id', this.basketId);
            this.$store.dispatch('lenses/uploadPrescription', formData);
        },
    },
    watch: {
        uploadPrescriptionIsLoading(newVal, oldVal) {
            if (oldVal && !newVal) {
                this.$store.dispatch('cart/getCart');
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-card-cart-prescription {
    position: relative;
    &__input {
        position: absolute;
        width: 0;
        height: 0;
        overflow: hidden;
        opacity: 0;
        pointer-events: none;
    }
    &__trigger {
        .mixin-link-decoration();

        cursor: pointer;
    }
    &__error {
        color: @color-accent-warm;
    }
    @media @media-xs-down {
    }
}
</style>
