<template>
    <section class="plugin-infopage-cover"
        v-bind:class="'_theme_' + theme"
        >
        <div class="plugin-infopage-cover__image">
            <slot />
        </div>
        <div class="plugin-infopage-cover__content">
            <div class="plugin-infopage-cover__content-breadcrumbs"
                v-if="breadcrumbs"
                v-html="breadcrumbs"
            />
            <h1 class="plugin-infopage-cover__content-title"
                v-text="title"
            />
            <div class="plugin-infopage-cover__content-link"
                v-if="url"
                >
                <common-customizable-link class="plugin-infopage-cover__content-link-text"
                    v-bind:href="url"
                    v-bind:target="isNewWindowNeeded ? '_blank' : '_self'"
                    >
                    {{ urlTitle || 'Ссылка' }}
                </common-customizable-link>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'plugin-infopage-cover',
    props: {
        title: String,
        theme: String,
        breadcrumbs: {
            type: String,
            default: null,
        },
        url: {
            type: String,
            default: null,
        },
        urlTitle: {
            type: String,
            default: null,
        },
        isNewWindowNeeded: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        component() {
            if (this.url) {
                return 'a';
            }
            return 'div';
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.plugin-infopage-cover {
    position: relative;
    &._theme_light {
        color: @color-gray-darkest;

        background-color: @color-gray-lighter;
    }
    &._theme_dark {
        color: @color-gray-lightest;

        background-color: @color-gray-main;
    }
    &__content {
        position: absolute;
        top: 0;
        left: 0;

        display: grid;
        grid-template-columns: 1fr auto;
        grid-template-rows: 1fr auto;
        align-items: end;
        height: 100%;
        width: 100%;
        padding: 40px 32px;
        &-breadcrumbs {
            grid-column: 1;
            grid-row: 1;
            width: 100%;
            margin-bottom: 4px;
            & /deep/ * {
                .typography-caption-lg();

                margin: 0;
                width: fit-content;
                text-decoration: none;

                color: currentColor;
            }
        }
        &-title {
            .typography-display-md();

            grid-column: 1;
            grid-row: 2;
            margin: 0;

            font-weight: normal;
            text-transform: uppercase;
        }
        &-link {
            .typography-display-md();

            grid-column: 2;
            grid-row: 2;

            text-transform: uppercase;
            &-text {
                .mixin-link-decoration-invert();
                &:after {
                    content: ' ↗︎\FE0E';
                }
            }
        }
    }
    @media @media-md-down {
        &__content {
            padding: 28px 20px 20px;
            grid-template-columns: 1fr;
            grid-template-rows: auto 1fr auto;
            align-items: flex-start;
            justify-content: flex-start;
            &-link {
                .typography-display-sm();

                justify-self: flex-end;
                grid-column: 1;
                grid-row: 3;
            }
        }
    }
}
</style>
