<template>
    <div class="page-checkout-content">
        <main class="page-checkout-content__forms">
            <transition name="fade">
                <div class="page-checkout-content__forms-loader"
                    v-if="isLoading"
                    >
                    <div class="page-checkout-content__forms-loader-container">
                        <ui-loader />
                    </div>
                </div>
            </transition>
            <section class="page-checkout-content__forms-auth"
                v-if="activeStepCode === 'shipping'"
                >
                <page-checkout-auth />
            </section>
            <section class="page-checkout-content__forms-steps">
                <div class="page-checkout-content__forms-steps-item"
                    v-for="(step, i) in stepsFiltered"
                    v-bind:key="i"
                    >
                    <page-checkout-step
                        v-bind:is-active="step.code === activeStepCode"
                        v-bind:step="step"
                    />
                </div>
            </section>
            <section class="page-checkout-content__forms-help">
                Нужна помощь?
                <br/>
                <a href="tel:+78005552497">+7 (800) 555-24-97</a>
                <br/>
                <ui-trigger v-on:click="$store.commit('common/chatraOpen')">Спросить в чате</ui-trigger>
            </section>
        </main>
        <section class="page-checkout-content__cart">
            <page-checkout-cart />
        </section>
    </div>
</template>

<script>
import Vue from 'vue';
import config from '~/config';
import {
    checkoutStepPayment,
    checkoutStepShipping,
    checkoutStepBeginning,
} from '~/utils/ecommerce';

const localStorageKey = config.localStorage.prefix + config.localStorage.affixes.checkout.savedData;
const localStoragePhoneConfirmationKey = config.localStorage.prefix + config.localStorage.affixes.checkout.phoneConfirmation;

export default {
    name: 'page-checkout-content',
    props: {
        mode: {
            type: String,
            default: 'checkout',
        },
    },
    provide() {
        return {
            pageCheckoutEventBus: this.pageCheckoutEventBus,
            mode: this.mode,
            cartModuleName: this.cartModuleName,
        };
    },
    data: () => ({
        pageCheckoutEventBus: new Vue(),
        isStepTransitionActive: true,
        transitionDuration: 300,
        steps: [
            {
                code: 'shipping',
                title: 'Способ доставки',
                customizedTitle: null,
                isFilled: false,
                component: 'shipping',
            },
            {
                code: 'payment',
                title: 'Способ оплаты',
                customizedTitle: null,
                isFilled: false,
                component: 'payment',
            },
        ],
        activeStepCode: 'shipping',
    }),
    computed: {
        productsEcommerceData() {
            return this.$store.getters[this.cartModuleName + '/productsEcommerceData'];
        },
        cartModuleName() {
            return this.mode === 'fitting' ? 'fitting' : 'cart';
        },
        checkoutData() {
            return this.$store.state.checkout.data;
        },
        cart() {
            return this.$store.state[this.cartModuleName].getCartResult;
        },
        phoneError() {
            return this.$store.state.checkout.error === 'The phone number entered is not valid.';
        },
        user() {
            return this.$store.getters['user/user'];
        },
        isLoading() {
            return this.isStepTransitionActive ||
                this.$store.state.areas.getAreasIsLoading ||
                this.$store.state.shippingMethods.getShippingMethodsIsLoading ||
                this.$store.state.shippingMethods.getShippingPriceIsLoading ||
                this.$store.state.stores.getStoresIsLoading ||
                this.$store.state.paymentMethods.getPaymentMethodsIsLoading ||
                this.$store.state.communication.getCommunicationListIsLoading ||
                this.$store.getters['checkout/state'].isLoading ||
                this.$store.getters['user/isLoading'];
        },
        stepsFiltered() {
            return this.steps.filter(x => !x.validModes || x.validModes.indexOf(this.mode) > -1);
        },
        isDadataAvailable() {
            return this.$store.getters['checkout/isDadataAvailable'];
        },
        isSelfPickup() {
            return this.$store.getters['checkout/isSelfPickup'];
        },
        fullAddress() {
            return this.$store.getters['checkout/fullAddress'];
        },
        shippingPriceSignature() {
            // when this value changes, we should reset calculated price
            // and try to calculate it again if this action is available
            return `${this.checkoutData.cityFiasId}-${this.checkoutData.cityCdekCode}-${this.checkoutData.shippingMethod?.type}-${this.$store.getters['checkout/isShippingFree']}`;
        },
    },
    methods: {
        setStepHandler(newStep) {
            this.isStepTransitionActive = true;
            this.activeStepCode = newStep.code;
            setTimeout(() => {
                this.isStepTransitionActive = false;
            }, this.transitionDuration);
        },
        stepSubmitHandler(submittedStep) {
            const stepIndex = this.steps.findIndex(x => x.code === submittedStep.code);
            this.steps[stepIndex].isFilled = true;
            if (submittedStep.code === 'shipping') {
                if (this.user) {
                    this.updateUserData();
                }
                checkoutStepShipping({
                    total: this.cart.total,
                    shippingType: this.checkoutData.shippingMethod
                        ? this.checkoutData.shippingMethod.optionTitle
                        : 'Без доставки',
                    products: this.productsEcommerceData,
                }, this.mode === 'fitting');
                this.activeStepCode = 'payment';
            } else if (submittedStep.code === 'payment') {
                let isPopupShown = false;
                let timeout = setTimeout(() => {
                    clearTimeout(timeout);
                    timeout = null;
                    this.checkPhone();
                    isPopupShown = true;
                }, 500);

                checkoutStepPayment(
                    {
                        total: this.cart.total,
                        paymentType: this.checkoutData.paymentMethod.optionTitle,
                        products: this.productsEcommerceData,
                    },
                    () => {
                        if (timeout) {
                            clearTimeout(timeout);
                            timeout = null;
                        }
                        if (!isPopupShown) {
                            this.checkPhone();
                            isPopupShown = true;
                        }
                    },
                    this.mode === 'fitting',
                );
            }
        },
        checkPhone() {
            if (this.isPhoneConfirmationValid()) {
                this.checkout();
            } else {
                this.showPhoneConfirmation();
            }
        },
        phoneConfirmationCallback(code) {
            if (!code) {
                this.$store.commit('modals/push', {
                    name: 'common',
                    props: {
                        title: 'Произошла ошибка',
                        text: 'Ошибка валидации кода подтверждения',
                    },
                });
                return;
            }
            window.localStorage.setItem(
                localStoragePhoneConfirmationKey,
                JSON.stringify({
                    number: this.checkoutData.phone,
                    timestamp: Date.now(),
                    code,
                })
            );
            this.checkPhone();
        },
        isPhoneConfirmationValid() {
            if (this.$store.state.user.user) {
                return true;
            }
            const phoneConfirmationStoredData = JSON.parse(window.localStorage.getItem(localStoragePhoneConfirmationKey) || 'null');
            const result = phoneConfirmationStoredData &&
                phoneConfirmationStoredData.number === this.checkoutData.phone &&
                Date.now() - phoneConfirmationStoredData.timestamp < 270000;
            return result;
        },
        clearPhoneConfirmation() {
            window.localStorage.setItem(
                localStoragePhoneConfirmationKey,
                JSON.stringify({
                    number: this.checkoutData.phone,
                    timestamp: 0,
                    code: null,
                })
            );
        },
        showPhoneConfirmation() {
            this.$store.commit('modals/push', {
                name: 'sms-verification',
                props: {
                    phone: this.checkoutData.phone,
                    callback: this.phoneConfirmationCallback,
                },
            });
        },
        updateUserData() {
            const result = {};
            const [
                firstName,
                lastName,
            ] = (this.checkoutData.name || '').split(' ').map(x => x.trim()).filter(x => !!x);

            if (firstName && firstName !== this.user.firstName) {
                result.firstName = firstName;
            }
            if (lastName && lastName !== this.user.lastName) {
                result.lastName = lastName;
            }
            this.$store.dispatch('user/update', result);
        },
        checkout() {
            const checkoutParams = {
                type: this.mode,
                productsEcommerceData: this.productsEcommerceData,
                code: JSON.parse(window.localStorage.getItem(localStoragePhoneConfirmationKey))?.code,
            };
            if (this.cart && !this.cart.isShippingNeeded) {
                checkoutParams.noShipping = true;
            }
            this.$store.dispatch('checkout/checkout', checkoutParams);
        },
    },
    mounted() {
        // analytics
        checkoutStepBeginning({
            total: this.cart.total,
            products: this.productsEcommerceData,
        }, this.mode === 'fitting');

        // listeners
        this.pageCheckoutEventBus.$on('step-completed', this.stepSubmitHandler);
        this.pageCheckoutEventBus.$on('set-step', this.setStepHandler);

        // go to payment step
        // and set title for it
        // if it is fitting mode
        if (this.mode === 'fitting') {
            const stepIndex = this.steps.findIndex(x => x.code === 'payment');
            this.steps[stepIndex].title = 'Авторизация карты';
        }

        // animations fix for transitions between steps
        setTimeout(() => {
            this.isStepTransitionActive = false;
        }, this.transitionDuration);

        // fix to reload page on back button
        window.onpageshow = function(event) {
            if (event.persisted) {
                window.location.reload();
            }
        };

        // saving phone number if available
        if (this.user?.phone && this.checkoutData.phone !== this.user.phone) {
            this.clearPhoneConfirmation();
            this.$store.commit('checkout/updateData', {
                phone: this.user.phone,
            });
        }
    },
    beforeDestroy() {
        this.pageCheckoutEventBus.$off('step-completed');
        this.pageCheckoutEventBus.$off('set-step');
    },
    watch: {
        shippingPriceSignature: {
            handler() {
                // value itself doesn't matter, only the fact that it changed
                // in any case reset previous result and error
                this.$store.state.shippingMethods.getShippingPriceResult = null;
                this.$store.state.shippingMethods.getShippingPriceError = null;
                // if shipping calculation is available
                if (this.$store.getters['checkout/isShippingCalculationAvailable']) {
                    // get new shipping price
                    this.$store.dispatch('shippingMethods/getShippingPrice');
                }
            },
            immediate: true,
        },
        checkoutData(newVal, oldVal) {
            // saving data to loacalstorage to reuse after page reload
            if (newVal.specialAddress !== null) {
                // clear address fields if special address is set
                window.localStorage.setItem(localStorageKey, JSON.stringify({
                    ...newVal,
                    apartment: null,
                    postcode: null,
                    specialAddress: null,
                    street: null,
                }));
            } else {
                // or save all fields
                window.localStorage.setItem(localStorageKey, JSON.stringify(newVal));
            }
            // reset phone confirmation if phone number is changed
            if (newVal.phone !== oldVal.phone) {
                this.clearPhoneConfirmation();
            }
            // reset shipping method and city if country is changed
            if (newVal.country !== oldVal.country || newVal.customCountry !== oldVal.customCountry) {
                this.$store.commit('checkout/updateData', {
                    city: null,
                    customCity: null,
                    shippingMethod: null,
                });
            }
        },
        isDadataAvailable() {
            this.$store.commit('checkout/updateData', {
                customCity: null,
            });
        },
        'checkoutData.shippingMethod': {
            immediate: true,
            handler(newVal) {
                const stepIndex = this.steps.findIndex(x => x.code === 'shipping');
                this.steps[stepIndex].customizedTitle = newVal?.code === 'self-pickup' ? 'Самовывоз'
                    : newVal === null ? 'Без доставки' : 'Курьер';
            },
        },
        phoneError(newVal) {
            if (newVal) {
                this.setStepHandler(this.steps[0]);
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.page-checkout-content {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    width: 100%;
    height: 100%;
    &__forms {
        .container-paddings();

        position: relative;

        flex: 1 1 auto;
        width: 50%;
        height: 100%;
        padding-top: 36px;
        padding-bottom: 48px;
        overflow: auto;
        &-loader {
            .transition-fade();

            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;

            display: flex;

            background-color: @color-gray-lightest;
            &.fade-enter {
                opacity: 1;
            }
            &-container {
                width: 100px;
                margin: auto;
            }
        }
        &-auth {
            max-width: 500px;
            margin-bottom: 36px;
        }
        &-steps {
            padding-bottom: 50px;

            counter-reset: step 0;
            &-item {
                & ~ & {
                    margin-top: 24px;
                }
            }
        }
        &-help {
            display: none;
        }
    }
    &__cart {
        flex: 1 1 auto;
        width: 50%;
        height: 100%;
        overflow: auto;

        background-color: @color-gray-lighter;
    }
    @media @media-md-down {
        flex-direction: column;
        height: auto;
        &__forms {
            width: 100%;
            padding-top: 20px;
            &-help {
                display: block;

                line-height: 2;
                &-trigger {
                    .mixin-link-decoration();

                    cursor: pointer;
                }
            }
        }
        &__cart {
            order: -1;
            width: 100%;
        }
    }
    @media @media-sm-down {
        &__forms {
            width: 100%;
        }
        &__step {
            margin-bottom: 20px;
            &:before {
                width: 100%;
            }
            &-done {
                width: 100%;
            }
            &-error {
                &-button {
                    width: 100%;
                }
            }
        }
    }
}
</style>
