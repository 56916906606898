<template>
    <header class="common-header">
        <div class="common-header__content"
            v-if="variant === 'checkout'"
            >
            <a class="common-header__content-logo" href="/">
                <img class="common-header__content-logo-image"
                    v-bind:src="staticPathRoot + '/logo.svg'"
                    alt="P.Y.E site logo"
                />
            </a>
        </div>
        <div class="common-header__content"
            v-else
            >
            <a class="common-header__content-logo" href="/">
                <img class="common-header__content-logo-image"
                    v-bind:src="staticPathRoot + '/logo.svg'"
                    alt="P.Y.E site logo"
                />
            </a>
            <div class="common-header__content-menu">
                <common-header-main-menu
                    v-bind:items="mainItems"
                    >
                </common-header-main-menu>
            </div>
            <div class="common-header__content-search"
                v-on:click="toggleSearch"
                >
                <div class="common-header__content-search-icon">
                    <icon name="search" />
                </div>
                <div class="common-header__content-search-text">
                    Поиск
                </div>
            </div>
            <div class="common-header__content-additional">
                <a class="common-header__content-additional-link"
                    v-for="item in additionalItems"
                    v-bind:key="item.pk"
                    v-bind:href="item.url"
                    v-bind:target="item.target || '_self'"
                    v-text="item.name"
                />
            </div>
            <div class="common-header__content-auth"
                v-if="user === null"
                v-on:click="showAuthModal"
                >
                Войти
            </div>
            <a v-bind:href="cabinetHref" class="common-header__content-auth"
                v-else
                v-text="user.firstName ? user.firstName : 'Личный кабинет'"
            />
            <div class="common-header__content-tryon">
                <common-header-tryon />
            </div>
            <div class="common-header__content-cart">
                <common-header-cart />
            </div>
            <div class="common-header__content-burger"
                v-on:click="toggleMenu"
                v-bind:class="{ _active: isMenuActive }"
                >
                <div class="common-header__content-burger-top" />
                <div class="common-header__content-burger-mid" />
                <div class="common-header__content-burger-bot" />
            </div>
            <transition name="fade">
                <div class="common-header__search"
                    v-if="isSearchActive"
                    >
                    <common-header-search
                        v-on:toggle="toggleSearch"
                    />
                </div>
            </transition>
            <transition name="fade">
                <div class="common-header__menu"
                    v-if="isMenuActive"
                    >
                    <div class="common-header__menu-placeholder"
                        v-bind:style="{ height: placeholderHeight + 'px' }"
                    />
                    <div class="common-header__menu-content">
                        <common-header-mobile-menu
                            v-on:toggle-auth="showAuthModal"
                            v-bind:items="mobileItems"
                            v-bind:footer="mobileFooter"
                        />
                    </div>
                </div>
            </transition>
        </div>
    </header>
</template>

<script>
import { mapState } from 'vuex';
import config from '~/config';

export default {
    name: 'common-header',
    inject: [ 'staticPathRoot' ],
    props: {
        variant: {
            type: String,
            default: 'normal',
        },
    },
    data: () => ({
        cabinetHref: config.urls.cabinet,
        isMenuActive: false,
        isSearchActive: false,
        placeholderHeight: 0,
    }),
    computed: {
        ...mapState({
            mainItems: state => state.menu.header.main?.items || [],
            additionalItems: state => state.menu.header.additional?.items || [],
            mobileItems: state => state.menu.header.mobile?.items || [],
            mobileFooter: state => state.menu.header.mobileFooter?.items,
        }),
        user() {
            return this.$store.getters['user/user'];
        },
    },
    methods: {
        toggleSearch() {
            this.isSearchActive = !this.isSearchActive;
            this.isMenuActive = false;
        },
        toggleMenu() {
            this.isMenuActive = !this.isMenuActive;
        },
        showAuthModal() {
            this.$store.commit('modals/push', 'auth');
        },
        calculateNotificationsHaight() {
            const notifications = this.$store.state.notifications.items;
            const toolbar = document.querySelector('.cms-toolbar');
            let result = 0;
            if (notifications.length > 0) {
                const notificationsContainer = document.getElementById('notifications-root');
                result = Math.max(
                    notificationsContainer.offsetHeight +
                    notificationsContainer.getBoundingClientRect().top,
                    0
                );
            }
            if (toolbar) {
                result = Math.max(
                    result,
                    Math.max(
                        toolbar.offsetHeight +
                        toolbar.getBoundingClientRect().top,
                        0
                    )
                );
            }
            this.placeholderHeight = result;
        },
    },
    watch: {
        isMenuActive(newVal) {
            if (newVal) {
                this.calculateNotificationsHaight();
                this.$store.commit('common/increaseBodyFixedCounter');
            } else {
                this.$store.commit('common/decreaseBodyFixedCounter');
            }
        },
    },
};
</script>

<style scoped lang="less">
@import '~theme';

.common-header {
    position: relative;
    z-index: 100;

    font-size: 1.4rem;
    line-height: 2rem;

    background: @color-gray-lightest;
    &__placeholder {
        display: none;
    }
    &__content {
        .container();
        .container-paddings();

        display: flex;
        justify-content: flex-start;
        align-items: stretch;
        width: 100%;
        height: @container-header-height;
        &-burger {
            display: none;
        }
        &-logo {
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            height: 100%;
            margin-right: 37px;
            margin-left: 12px;

            text-decoration: none;
            &-image {
                display: block;
                width: auto;
                height: 28px;
            }
        }
        &-menu {
            flex: 0 1 auto;
            height: 100%;
        }
        &-search {
            position: relative;

            flex: 0 0 auto;
            display: flex;
            align-items: center;
            margin-right: 17px;
            margin-left: auto;

            color: @color-gray-darkest;

            cursor: pointer;

            transition: color @duration-fast @easing-default;
            &-icon {
                font-size: 1.3rem;
            }
            &-text {
                margin-left: 8px;
            }
            &:hover {
                color: @color-accent-warm;
            }
            &:before {
                content: '';

                position: absolute;
                top: 50%;
                left: 50%;
                z-index: 1;

                display: block;
                width: 40px;
                height: 40px;
                margin-top: -20px;
                margin-left: -20px;
            }
        }
        &-additional {
            flex: 0 0 auto;
            height: 100%;

            text-decoration: none;
            &:hover {
                /*text-decoration: underline;*/
            }
            &-link {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                padding: 0 16px;

                .mixin-link-decoration-invert();
            }
        }
        &-auth {
            flex: 0 1 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            padding: 0 16px;
            margin-right: 0;
            margin-left: 0;
            cursor: pointer;
            .mixin-link-decoration-invert();
        }
        &-tryon {
            align-self: center;
            margin-left: 26px;
        }
        &-cart {
            align-self: center;
            margin-right: 13px;
            margin-left: 27px;
        }
    }
    &__search {
        .transition-fade();

        position: absolute;
        top: 100%;
        right: 0;
        z-index: 3;

        width: 25vw;
        max-width: 100%;

        background-color: @color-gray-lightest;
    }
    &__auth {
        .transition-fade();

        position: absolute;
        top: 100%;
        right: 0;
        z-index: 3;

        width: 360px;
        max-width: 100%;
        padding: 20px 20px 0;

        background-color: @color-gray-main;
    }
    &__menu {
        .transition-fade();

        position: fixed;
        top: @container-header-height;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 5;

        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;

        pointer-events: none;
        // .cms-toolbar-expanded & {
        //     top: calc(@cms-toolbar-height + @container-header-height);
        // }
        &-placeholder {
            flex: 0 0 auto;
            height: 0;

            opacity: 0;
            pointer-events: none;
        }
        &-content {
            flex: 1 1 auto;
            min-height: 0;

            pointer-events: all;
        }
    }
    @media @media-lg-down {
        &__content {
            &-search {
                &-text {
                    display: none;
                }
            }
        }
        &__search {
            width: 37.5vw;
        }
    }
    @media @media-md-down {
        &__content {
            &-logo {
                margin-left: 0;
                margin-right: auto;
            }
            &-menu {
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 2;

                flex-direction: column;
                align-items: stretch;
                height: auto;

                background-color: @color-gray-main;

                opacity: 0;
                pointer-events: none;

                transition: opacity @duration-fast @easing-default;

                &._active {
                    opacity: 1;
                    pointer-events: all;
                }
                &-item {
                    justify-content: flex-start;
                    align-items: center;
                    padding: 13px 31px 13px 20px;
                    &:first-child {
                        padding-top: 26px;
                    }
                    &:last-child {
                        padding-bottom: 20px;
                    }
                }
            }
            &-search {
                margin-right: 0;
                margin-left: auto;
                &-icon {
                    font-size: 2rem;
                }
            }
            &-additional {
                display: none;
            }
            &-auth {
                padding: 3px 10px 0;
                display: none;
            }
            &-cart {
                margin-left: 26px;
                margin-right: 25px;
            }
            &-burger {
                position: relative;

                flex: 0 0 auto;
                display: block;
                width: 32px;
                height: 17px;
                margin: auto 4px auto 0;

                color: @color-gray-darkest;

                cursor: pointer;

                &:after {
                    content: '';

                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 1;

                    display: block;
                    width: 40px;
                    height: 40px;
                    margin-top: -20px;
                    margin-left: -20px;
                }
                &-top,
                &-mid,
                &-bot {
                    position: absolute;
                    left: 0;

                    width: 100%;
                    height: 1px;

                    background-color: currentColor;

                    transition: transform @duration-fast @easing-default;
                }
                &-top {
                    top: 0;

                    transform-origin: left center;
                    ._active > & {
                        transform: translate(6px, -2px) rotate(45deg) scaleX(0.9);
                    }
                }
                &-mid {
                    top: 50%;

                    margin-top: -1px;

                    transform-origin: center;
                    ._active > & {
                        transform: scaleX(0);
                    }
                }
                &-bot {
                    bottom: 0;

                    transform-origin: left center;
                    ._active > & {
                        transform: translate(6px, 2px) rotate(-45deg) scaleX(0.9);
                    }
                }
            }
        }
        &__auth {
            padding-left: 10px;
            padding-right: 10px;
        }
        &__search {
            width: 50vw;
        }
    }
    @media @media-sm-down {
        &__search {
            position: absolute;
            top: 100%;
            right: 0;
            z-index: 3;

            width: 100%;
        }
    }
}
</style>
